import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

window.isManual = false;
const app = createApp(App);
app.use(store).use(router).mount("#cat-app");
// implement mdifonts package throug mdi-vue wrapper
app.use(mdiVue, {
  icons: mdijs
});

/**
 * error handler will send all
 * errors to the api for report purpose
 */
app.config.errorHandler = (error) => {
  store.dispatch("error/report", { error, route: window.location.href });
};