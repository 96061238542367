<template>
  <base-icons />
  <router-view />
</template>

<script>
import BaseIcons from "@/components/UI/BaseIcons.vue";

export default {
  components: {
    BaseIcons,
  },
  created() {
    this.$store.dispatch("auth/checkLogin");
    this.$store.dispatch("activity/checkActivity");
  },
  mounted() {
    /**
     * method to automaticall update any 
     * open tab with the same session in order
     * to prevent errors like when users are
     * using e.g. loginAs function and switching
     * between tabs without reloading
     * with this, tokens are check in background 
     */
    let lastToken = localStorage.getItem("token");
    window.setInterval(() => {
      const tokenChanged = lastToken !== localStorage.getItem("token");
      if(!localStorage.getItem("token")) {
        if(this.$router.currentRoute.value.meta?.unauthedOnly === undefined
          || this.$router.currentRoute.value.meta?.unauthedOnly !== true) {
          // $router push or replace are not working here for some reason - but only if the browsertab is not active
          if(document.hidden) {
            window.location.href = "/session-closed"
          }
        }
      } else {
        if(this.$router.currentRoute.value.meta?.unauthedOnly
          && this.$router.currentRoute.value.meta?.unauthedOnly === true) {
          // $router push or replace are not working here for some reason - but only if the browsertab is not active
          if(document.hidden) {
            window.location.href = "/courses"
          }
        }
        // if token changed - force a full reload
        if(tokenChanged) {
          // refresh last token - but only if the browsertab is not active
          lastToken = localStorage.getItem("token");
          if(document.hidden) {
            window.location.reload(true);
          }
        }
      }
    }, 2000)
  }
};
</script>

<style lang="scss">
@import "@/scss/style";
</style>
